import { usePageContext } from 'utils/src/pageContext'
import { usePageAssemblyPath } from '@cw-monorepo/contentful/src/hooks/usePageAssemblyPath'
import type * as Contentful from 'cw-frontend/src/types/contentful'
import type { ContentfulLink } from 'cw-frontend/src/types/contentful/old'
import type { MaybeValue } from 'cw-frontend/src/types/utils'

export function useLink(link: MaybeValue<Contentful.Link | ContentfulLink>) {
  const corePath = isCoreLink(link) ? getCoreLinkPath(link) : undefined
  const oldPath = isOldLink(link) ? getOldLinkPath(link) : undefined
  const linkPath = corePath || oldPath

  const navigate = () => {
    if (linkPath) window.location.assign(linkPath)
  }

  return { navigate }
}

function getCoreLinkPath(data: Contentful.Link): string | undefined {
  if (data.externalUrl) return data.externalUrl

  const { allPages } = usePageContext()
  const contentId = data.content?.id

  const path: string | undefined = contentId ? allPages[contentId] : undefined

  if (path) {
    return path?.startsWith('/') ? path : `/${path}`
  }

  return path
}

function getOldLinkPath(data: ContentfulLink): string {
  if (data.externalLink) return data.externalLink
  if (!data.internalLink?.slug) return ''

  const slug = usePageAssemblyPath(data.internalLink.slug, data.node_locale ?? '')

  return slug
}

function isCoreLink(link: any): link is Contentful.Link {
  return link && process.env.GATSBY_REPO === 'core'
}

function isOldLink(link: any): link is ContentfulLink {
  return link && process.env.GATSBY_REPO === 'old'
}
