import React from 'react'
import styled, { css } from 'styled-components'
import LoadingSpinner from './MapLoadingSpinner/mapLoadingSpinner'
import { above, color, costaTextBold } from './styles'

type Variant = 'primary' | 'secondary' | 'outline'

type IconType = false | 'submit' | 'back'

interface Props {
  variant: Variant
  children: React.ReactNode
  className?: string
  type?: 'button' | 'submit' | 'reset'
  id?: string
  loading?: boolean
  disabled?: boolean
  icon?: IconType
  onClick?: () => void
}

function Button({
  variant,
  children,
  className,
  id,
  type = 'button',
  loading = false,
  disabled = false,
  icon = false,
  onClick = () => undefined,
}: Props): React.ReactElement {
  return (
    <StyledButton
      className={className}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      id={id}
    >
      <LoadingMask hide={loading}>
        {icon === 'back' && <IconBack />}
        {children}
        {icon === 'submit' && <IconSubmit />}
      </LoadingMask>
      {loading && <StyledLoadingSpinner text="" />}
    </StyledButton>
  )
}

const applyVariant = (variant: Variant) => css`
  ${variant === 'primary' &&
  css`
    background-color: ${color.costaRed};
    color: ${color.white};
    border: 2px solid ${color.costaRed};

    &:is(:hover, :focus) {
      background-color: ${color.lightRed};
      border-color: ${color.lightRed};
    }
  `}
  ${variant === 'secondary' &&
  css`
    background-color: ${color.white};
    color: ${color.costaRed};
    border: 2px solid ${color.white};

    &:is(:hover, :focus) {
      background-color: ${color.lightRed};
      color: ${color.white};
      border-color: ${color.lightRed};
    }
  `}
  ${variant === 'outline' &&
  css`
    background-color: ${color.white};
    color: ${color.costaRed};
    border: 2px solid ${color.costaRed};

    &:is(:hover, :focus),
    &.focus {
      background-color: ${color.lightRed};
      border-color: ${color.lightRed};
      color: ${color.white};
    }
  `}
`

// const applySize = () => css``

const StyledButton = styled.button<{ variant: Variant }>`
  position: relative;
  display: flex;
  place-items: center;
  padding: 15px 20px;
  border: none;
  border-radius: 32px;
  ${costaTextBold};
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
  display: block;
  min-height: 42px;

  ${above.tablet`
    font-size: 18px;
    padding: 18px 27px;
  `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${color.greyCore};
    border-color: ${color.greyCore};

    &:is(:hover, :focus) {
      background-color: ${color.greyCore};
      border-color: ${color.greyCore};
    }
  }

  ${({ variant }) => applyVariant(variant)};
`

const LoadingMask = styled.span<{ hide: boolean }>`
  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
    `}
`

const IconSubmit = styled.span`
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid ${color.white};
  display: inline-block;
  margin: 0 0 0 15px;

  ${above.tablet`
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 8px solid ${color.white};
    `}
`

const IconBack = styled.span`
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 6px solid ${color.white};
  display: inline-block;
  margin: 0 15px 0 0;

  ${above.tablet`
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 8px solid ${color.white};
    `}
`

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  margin: 0;
  height: 42px;
  width: 42px;

  > div {
    height: 100% !important;
    width: 100% !important;
  }

  > div::before {
    background-color: ${color.white} !important;
  }
`

export default Button
