import { useState } from 'react'
import axios from 'axios'

import type { FormFields } from '.'

type FormFieldKeys = keyof FormFields

const endpoint = process.env.GATSBY_B2B_CONTACT_FORM_ENDPOINT ?? 'unknown'
const b2bEmailEndpoint = process.env.GATSBY_B2B_EMAIL_ENDPOINT

export function useApi() {
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const submit = async (data: FormFields) => {
    if (submitting) return

    try {
      setSubmitting(true)
      if (b2bEmailEndpoint) {
        await axios.post(b2bEmailEndpoint, transformB2bEmailData(data))
      } else {
        await axios.post(endpoint, mapData(data))
      }
      setSuccess(true)
    } catch {
      setError(true)
    } finally {
      setSubmitting(false)
    }
  }

  const reset = () => {
    setError(false)
    setSuccess(false)
  }

  return { reset, error, success, submitting, submit }
}

const apiFieldsMap: Record<FormFieldKeys, string> = {
  firstName: 'firstname',
  lastName: 'lastname',
  email: 'emailaddress1',
  phone: 'telephone1',
  jobTitle: 'jobtitle',
  companyName: 'companyname',
  industry: 'qq_productpropositionindustry',
  otherIndustry: 'qq_otherindustry',
  productProposition: 'qq_productproposition',
  otherProductProposition: 'qq_otherproductproposition',
  additionalComments: 'description',
  companySize: 'qq_companysizelm',
  sourceOfLead: 'leadsourcecode',
  leadSubSource: 'qq_leadsubsource',
  terms: 'qq_tclm',
  marketing: 'qq_marketingconsentlm',
  partnersMarketing: 'qq_marketingconsent2lm',
  contactUs: 'qq_contactus',
  countryCode: 'qq_countrycode',
  eventLead: 'qq_eventlead',
  gatedFormCompleted: 'qq_gatedcontentform',
  postcode: 'address1_postalcode',
}

function mapData(data: FormFields) {
  const mappedData: { [key: string]: FormFields[FormFieldKeys] } = {}

  for (const key in data) {
    const internalKey = key as FormFieldKeys
    const mappedKey = apiFieldsMap[internalKey]
    const value = data[internalKey]
    const isString = typeof value === 'string'
    const isNumber = typeof value === 'number'
    const isArray = Array.isArray(value) && value.length !== 0

    if (isString || isNumber || isArray) {
      mappedData[mappedKey] = value
    }
  }

  mappedData.subject = `${data.firstName} ${data.lastName}`.trim()

  return mappedData
}

function transformB2bEmailData(data: FormFields) {
  const {
    firstName,
    lastName,
    email,
    jobTitle,
    companyName,
    industry,
    additionalComments,
    terms,
    marketing,
    partnersMarketing,
    countryCode,
    phone,
    otherIndustry,
    otherProductProposition,
    companySize,
    productProposition,
  } = data

  return {
    firstName,
    lastName,
    email,
    jobTitle,
    companyName,
    industry,
    additionalComments,
    terms,
    marketing,
    partnersMarketing,
    countryCode,
    otherIndustry,
    otherProductProposition,
    // when it starts with 0 or 00 does not send them and strips them
    phone: phone.toString(),
    companySize,
    productProposition,
    mailTo: process.env.GATSBY_B2B_EMAIL,
  }
}
