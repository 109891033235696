import type * as Contentful from 'cw-frontend/src/types/contentful'
import type { MaybeValue } from 'cw-frontend/src/types/utils'

export function transformOptionItems(
  items: MaybeValue<Contentful.Data[]>,
  hasB2bEmailEndpoint: boolean
) {
  if (!items) return null

  return items.map(item => {
    const initialValue = item.valueNumber || item.value?.trim() || ''
    const value: string | number = hasB2bEmailEndpoint ? item.value?.trim() || '' : initialValue

    return { id: item.id ?? '', value, label: item.displayText ?? '' }
  })
}

export function getDataValue(entry: MaybeValue<Contentful.Data>, hasB2bEmailEndpoint: boolean) {
  const option = entry?.valueNumber || entry?.value?.trim()
  const enabledValue = entry?.value?.trim() || option

  return hasB2bEmailEndpoint ? enabledValue : option
}
